export const bookContent = {
  id: 10,
  name: '你不知道的JavaScript',
  author: 'Kyle Simpson',
  region: '美国',
  cover:
    'http://cover.read.duokan.com/mfsv2/download/s010/p01g8NOQ3Elv/azwcob3UZrT7X6.jpg',
  category: '编程',
  tags: ['JavaScript', 'Web'],
}
export const anotherBookContent = {
  id: 3,
  name: '平凡的世界',
  author: '路遥',
  region: '中国',
  cover:
    'https://bkimg.cdn.bcebos.com/pic/14ce36d3d539b600fbdea00ee950352ac65cb773?x-bce-process=image/resize,m_lfit,w_268,limit_1/format,f_jpg',
  category: '文学',
  tags: ['当代', '社会'],
}
export const books = [
  bookContent,
  anotherBookContent,
  {
    id: 2,
    name: 'Java编程思想(第4版)',
    author: '埃史尔',
    region: '美国',
    cover: 'https://www.linuxidc.com/upload/2014_08/140811101915661.jpg',
    category: '编程',
    tags: ['Java', 'Web'],
  },

  {
    id: 3,
    name: '深入浅出React和Redux',
    author: '程墨',
    region: '中国',
    cover: 'https://img9.doubanio.com/view/subject/l/public/s29456776.jpg',
    category: '前端',
    tags: ['Web', 'React'],
  },
  {
    id: 4,
    name: '国富论',
    author: '亚当·斯密',
    region: '英国',
    cover:
      'https://wiki.mbalib.com/w/images/thumb/3/38/%E5%9B%BD%E5%AF%8C%E8%AE%BA.jpg/190px-%E5%9B%BD%E5%AF%8C%E8%AE%BA.jpg',
    category: '经济',
    tags: ['经济', '社会'],
  },
]
